/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { md3 } from 'vuetify/blueprints'
import { fr, en } from 'vuetify/locale'

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify'
const holitimeLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#256c68',
    secondary: '#12c29b',
    'secondary-lighten-3': '#dff4ef',
    accent: '#f3cc71',
    'accent-lighten-1': '#f3d98c',
    'accent-lighten-2': '#fff2d5',
    accent2: '#ff958a',
    surface: '#ebfffe',
    'surface-lighten-1': '#E5F6EB',
    'surface-lighten-2': '#EEFFEF',
    'surface-blue': '#eef1fa',
    background: '#fff',
    'on-secondary': '#fff',
    'on-accent': '#fff',
    'on-accent2': '#12372f',
    gray: '#aaa1a1',
    'gray-100': '#FAFAFC',
    'gray-80': '#616161',
    green: '#00D575',
    'green-100': '#86A4A2',
    'green-200': '#458181',
    'gray-50': '#99A4AA',
    // 'gray-100': '#8F99A3',
    'gray-200': '#E4EDEA',
    'gray-300': '#636D79',
    'gray-400': '#9E9E9E',
    blue: '#1a73e9',
    'aero-blue': '#C4FFF2',
    'dark-green': '#004838',
    'jade-green': '#33CC99',
    'gray-ligth': '#C2C2C2',
    'ligth-gray': '#E0E0E0',
    'pale-green': '#F4FBF7',
    'pale-mind-green': '#E3F3E8',
    teal: '#007058',
    'teal-green': '#029978',
    darkgray: '#c1c1c1',
    'bright-red': '#FF5353',
    'light-pink': '#FFE8E8',
    'bright-orange': '#EE720D',
    'yellow-light': '#FEE29F',
    yellow: '#D6B25D',
    'yellow-dark': '#443513',
    'yellow-ligth': '#FFFBF1',
  },
}

export default createVuetify({
  blueprint: md3,
  theme: {
    defaultTheme: 'holitimeLightTheme',
    themes: {
      holitimeLightTheme,
    },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 2,
      darken: 2,
    },
  },
  defaults: {
    VBtn: { variant: 'flat', rounded: 'xl' },
    VTextField: { variant: 'outlined', rounded: true },
    VAutocomplete: {
      variant: 'outlined',
      rounded: true,
      autoSelectFirst: true,
    },
    VCombobox: { variant: 'outlined', rounded: true },
    VSelect: { variant: 'outlined', rounded: true },
    VTextarea: { variant: 'outlined' },
    VCard: { elevation: 1 },
  },
  locale: {
    messages: { fr, en },
    locale: 'fr',
    fallback: 'en',
  },
})
